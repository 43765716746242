'use strict';

angular.module('enervexSalesappApp').controller('ApplianceCategoriesCtrl', function($scope, ApplianceCategory, _) {
	$scope.allApplianceCategories = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		ApplianceCategory.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(applianceCategories){
			if (applianceCategories.length > 0) {
				$scope.allApplianceCategories = $scope.allApplianceCategories.concat(applianceCategories);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.applianceCategories = $scope.allApplianceCategories;
			}
		});
	}
	fetch();
	$scope.delete = function(applianceCategory) {
		ApplianceCategory.delete({
			id: applianceCategory._id
		});
		$scope.allApplianceCategories = _.filter($scope.allApplianceCategories, function(r){
			return r._id != applianceCategory._id
		})
		// $scope.applyFilter()
		$scope.applianceCategories = $scope.allApplianceCategories;
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fanPhases = _.filter($scope.allApplianceCategories, function(rule){
	// 		if (nameReg && !(nameReg.test(rule.action) || nameReg.test(rule.filter) || (rule.product && nameReg.test(rule.product.name)) || nameReg.test(rule.legacyId) )) {
	// 			return false;
	// 		} else if ($scope.category && $scope.category != '' && $scope.category != rule.category) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
